
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Avanzado extends Vue {
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: Number, required: true }) readonly value!: number;
    @Prop({ type: String, required: true, default: "" })
    readonly unitMeasure!: string;

    get fixedValue() {
        return this.value.toFixed(3);
    }
}
